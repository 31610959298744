import NavBar from './NavBar';
import Hero from './Hero';
import SectionTitle from './SectionTitle';
import './resources/css/App.css';
import TextWithCornerPhoto from './TextWithCornerPhoto';
import abigailPhoto from './resources/images/abigail-headshot-gartner.png';
import resume from './resources/attachments/abigail-bliss-resume.pdf';
import Box from './Box';
import Button from './Button';
// import PortfolioSection from './PortfolioSection';

function App() {
  const aboutText = [
    {
      text: 'I am a creator. No, not a content creator. My passion projects range from making furniture to filiming short videos, to designing websites to drawing and photography. I love to stand back from something and think "wow, I made that". I am currently a software engineer, but that is the least interesting thing about me. I am a lifelong learner- my number one value in life is curiosity. I am always asking, "why?" or "how does that work?". ',
      id: 1,
    },
    {
      text: 'Another value I have is movement. I am a marathon runner (can I call myself that after running two marathons?). In order to stay sane, I have to get out of my head and into my body. I love every aspect of running; the pre-run ritual, the miles stretching through Central Park and the getting home after to wind down. It keeps me grounded.',
      id: 2,
    },
    {
      text: 'Some real hard facts about me: I am from Madison Wisconsin. I went to the University of Minnesota and earned a degree in computer science and a minor in product design. I am 24 years old and come from a big family. I have a black belt in karate. I live in New York City and I am totally in love with this place. I am passionate about the things I choose, and those things vary widely. That is what makes me, me.',
      id: 3,
    },
    {
      text: '',
      id: 3,
    },
  ];
  return (
    <div className="App">
      <NavBar />
      <div className="main">
        <Hero />
        <SectionTitle titleRed="ABOUT" titleBlue="ME" id="about" />
        <TextWithCornerPhoto
          imgSource={abigailPhoto}
          imgSourceAltText="Photo of Abigail Bliss"
          photoPlacement="left"
          content={aboutText}
        />
        <SectionTitle titleRed="MY" titleBlue="LINKS" id="links" />
        <Box flexDirection="row">
          <Button shadowColor="red" buttonText="GitHub" buttonLink="https://github.com/Abigail-bliss"></Button>
          <Button
            shadowColor="blue"
            buttonText="LinkedIn"
            buttonLink="https://www.linkedin.com/in/abigailbliss1/"
          ></Button>
          <Button shadowColor="red" buttonText="Resume" buttonLink={resume}></Button>
        </Box>
        {/* <SectionTitle id="caninecardioclub-privacypolicy"></SectionTitle>
        <h2>Canine Cardio Club Privacy Policy</h2>
        <Box flexDirection="column">
          <div className="privacy-policy">
            <p>
              Your privacy is important to us. It is Abigail Bliss' policy to respect your privacy and comply with any
              applicable law and regulation regarding any personal information we may collect about you, including
              across our website, <a href="https://caninecardioclub.net/">https://caninecardioclub.net/</a>, and other
              sites we own and operate.
            </p>
            <p>This policy is effective as of May 4, 2024 and was last updated on May 4, 2024.</p>
            <h3>Information We Collect</h3>
            <p>
              Information we collect includes both information you knowingly and actively provide us when using or
              participating in any of our services and promotions, and any information automatically sent by your
              devices in the course of accessing our products and services.
            </p>
            <h3>Log Data</h3>
            <p>
              When you visit our website, our servers may automatically log the standard data provided by your web
              browser. It may include your device’s Internet Protocol (IP) address, your browser type and version, the
              pages you visit, the time and date of your visit, the time spent on each page, other details about your
              visit, and technical details that occur in conjunction with any errors you may encounter.
            </p>
            <p>
              Please be aware that while this information may not be personally identifying by itself, it may be
              possible to combine it with other data to personally identify individual persons.
            </p>
            <h3>Personal Information</h3>
            <p>We may ask for personal information which may include one or more of the following:</p>
            <ul>
              <li>Name</li>
              <li>Email</li>
              <li>Social media profiles</li>
              <li>Phone/mobile number</li>
            </ul>
            <h3>Legitimate Reasons for Processing Your Personal Information</h3>
            <p>
              We only collect and use your personal information when we have a legitimate reason for doing so. In which
              instance, we only collect personal information that is reasonably necessary to provide our services to
              you.
            </p>
            <h3>Collection and Use of Information</h3>
            <p>We may collect personal information from you when you do any of the following on our website:</p>
            <ul>
              <li>Use a mobile device or web browser to access our content</li>
              <li>Contact us via email, social media, or on any similar technologies</li>
              <li>When you mention us on social media</li>
            </ul>
            <p>
              We may collect, hold, use, and disclose information for the following purposes, and personal information
              will not be further processed in a manner that is incompatible with these purposes:
            </p>
            <ul>
              <li>to enable you to customize or personalize your experience of our website</li>
              <li>to contact and communicate with you</li>
              <li>
                for analytics, market research, and business development, including to operate and improve our website,
                associated applications, and associated social media platforms
              </li>
              <li>
                to enable you to access and use our website, associated applications, and associated social media
                platforms
              </li>
              <li>for internal record keeping and administrative purposes</li>
            </ul>
            <p>
              Please be aware that we may combine information we collect about you with general information or research
              data we receive from other trusted sources.
            </p>
            <h4>Security of Your Personal Information</h4>
            <p>
              When we collect and process personal information, and while we retain this information, we will protect it
              within commercially acceptable means to prevent loss and theft, as well as unauthorized access,
              disclosure, copying, use, or modification.
            </p>
            <p>
              Although we will do our best to protect the personal information you provide to us, we advise that no
              method of electronic transmission or storage is 100% secure, and no one can guarantee absolute data
              security. We will comply with laws applicable to us in respect of any data breach.
            </p>
            <p>
              You are responsible for selecting any password and its overall security strength, ensuring the security of
              your own information within the bounds of our services.
            </p>
            <h4>How Long We Keep Your Personal Information</h4>
            <p>
              We keep your personal information only for as long as we need to and only up to one year maximum. This
              time period may depend on what we are using your information for, in accordance with this privacy policy.
              If your personal information is no longer required, we will delete it or make it anonymous by removing all
              details that identify you.
            </p>
            <p>
              However, if necessary, we may retain your personal information for our compliance with a legal,
              accounting, or reporting obligation or for archiving purposes in the public interest, scientific, or
              historical research purposes or statistical purposes.
            </p>
            <h3>Children’s Privacy</h3>
            <p>
              We do not aim any of our products or services directly at children under the age of 13, and we do not
              knowingly collect personal information about children under 13.
            </p>
            <h3>International Transfers of Personal Information</h3>
            <p>
              The personal information we collect is stored and/or processed where we or our partners, affiliates, and
              third-party providers maintain facilities. Please be aware that the locations to which we store, process,
              or transfer your personal information may not have the same data protection laws as the country in which
              you initially provided the information. If we transfer your personal information to third parties in other
              countries: (i) we will perform those transfers in accordance with the requirements of applicable law; and
              (ii) we will protect the transferred personal information in accordance with this privacy policy.
            </p>
            <h3>Your Rights and Controlling Your Personal Information</h3>
            <p>
              You always retain the right to withhold personal information from us, with the understanding that your
              experience of our website may be affected. We will not discriminate against you for exercising any of your
              rights over your personal information. If you do provide us with personal information you understand that
              we will collect, hold, use and disclose it in accordance with this privacy policy. You retain the right to
              request details of any personal information we hold about you.
            </p>
            <p>
              If we receive personal information about you from a third party, we will protect it as set out in this
              privacy policy. If you are a third party providing personal information about somebody else, you represent
              and warrant that you have such person’s consent to provide the personal information to us.
            </p>
            <p>
              If you have previously agreed to us using your personal information for direct marketing purposes, you may
              change your mind at any time. We will provide you with the ability to unsubscribe from our email-database
              or opt out of communications. Please be aware we may need to request specific information from you to help
              us confirm your identity.
            </p>
            <p>
              If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant,
              or misleading, please contact us using the details provided in this privacy policy. We will take
              reasonable steps to correct any information found to be inaccurate, incomplete, misleading, or out of
              date.
            </p>
            <p>
              If you believe that we have breached a relevant data protection law and wish to make a complaint, please
              contact us using the details below and provide us with full details of the alleged breach. We will
              promptly investigate your complaint and respond to you, in writing, setting out the outcome of our
              investigation and the steps we will take to deal with your complaint. You also have the right to contact a
              regulatory body or data protection authority in relation to your complaint.
            </p>
            <h3>Limits of Our Policy</h3>
            <p>
              Our website may link to external sites that are not operated by us. Please be aware that we have no
              control over the content and policies of those sites, and cannot accept responsibility or liability for
              their respective privacy practices.
            </p>
            <h3>Changes to This Policy</h3>
            <p>
              At our discretion, we may change our privacy policy to reflect updates to our business processes, current
              acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we
              will post the changes here at the same link by which you are accessing this privacy policy.
            </p>
            <p>
              If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as
              applicable, any new uses of your personal information.
            </p>
            <h3>Contact Us</h3>
            <p>For any questions or concerns regarding your privacy, you may contact us using the following details:</p>
            <p>
              Abigail Bliss
              <br />
              abigaildbliss@gmail.com
            </p>
          </div>
        </Box> */}
        {/* <SectionTitle titleRed="PROJECTS" id="projects"/> */}
        {/* <PortfolioSection/> */}

        {/* <Box flexDirection="row">
          <Container></Container>
          <div className='row iframe__container'>
            <iframe className='iframe' width="300" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeClMvHwlECmnbzh4Z5ZutT%2FPersonal-Website-Mockup%3Fnode-id%3D0%253A1" allowfullscreen></iframe>
            <iframe className='iframe' width="300" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FDMJLuvIAvr5YWJ5874Sppd%2FPersonal-Website-Mobile%3Fnode-id%3D0%253A1" allowfullscreen></iframe>
          </div>
        </Box> */}
      </div>
    </div>
  );
}

export default App;
